import React from "react";
import { Link } from "react-router-dom";
import { useRest } from '@karpeleslab/react-klbfw-hooks';
import moment from "moment";
// import { HashLink } from 'react-router-hash-link';
import { useTranslation } from "react-i18next";
import { getLocale } from "@karpeleslab/klbfw";
// import ScrollLock from 'react-scrolllock';
import classNames from 'classnames';
// import { useWindowWidth } from '@react-hook/window-size';
// import Modal from "react-modal";

// component
import ComingSoon from "components/comingsoon/comingsoon";

// img
import mvSp from "assets/img/rocket-on/img-home-mainVisual--sp.jpg";
import mvPc from "assets/img/rocket-on/img-home-mainVisual--pc@2x.jpg";
import topicsDefault from "assets/img/rocket-on/img-topics-default.png";

export default function Index() {
	const { t } = useTranslation();
	const currentLng = getLocale();
	// const [ langModal, setLangModal ] = useState(false);
	// const [ toggleLock, setToggleLock ] = useState(false);
	// const [ scrollLock, setScrollLock ] = useState(false);

	// const onlyWidth = useWindowWidth();
	const [infoList] = useRest("Content/Cms/@news:search",{
		results_per_page: 5,
		query: {
			tag: 'info'
		},
		sort: "published:desc"
	});

	const imageVariation = 'format=jpeg&strip&scale_crop=374x247';
	const [topicsList] = useRest("Content/Cms/@news:search",{
		results_per_page:8,
		query: {
			tag: 'events'
		},
		"image_variation": imageVariation,
		sort: "published:desc"
	});

	const buildTopics = () => {

		if (topicsList === null) return false;
		
		if (topicsList.data.data.length === 0) {
			return <ComingSoon />
		}
		else {
			return (
				<div className="c-topics">
					<ul className="c-topics__list">
						{
							topicsList.data.data.map((item, idx) => {
								return (
									<li key={idx} className="c-topics__item">
										<a href={`/event/${item.Slug}`} className="c-topics__anchor">
											<img onContextMenu={(e) => {e.preventDefault(); e.stopPropagation(); return false}} src={!item.Drive_Item ? topicsDefault : item.Drive_Item.Media_Image[imageVariation]} alt="" className="c-topics__img"/>
											<span className="c-topics__date">{moment(item.Published.unix * 1000).format('YYYY/MM/DD')}</span>
											<span className="c-topics__title">{item.Title}</span>
										</a>
									</li>
								)
							})
						}
					</ul>
				</div>
			)
		}	
	}


	const buildArticle = () => {

		if (infoList === null) return false;
		
		if (infoList.data.data.length === 0) {
			return <ComingSoon />
		}
		else {
			return (
				<ul className="c-info-list__list">
					{
						infoList.data.data.map((item, idx) => {
							return (
								<li key={idx} className="c-info-list__item">
									<span className="c-info-list__date">{moment(item.Published.unix * 1000).format('YYYY/MM/DD')}</span>
									<span className="c-info-list__text">
										<Link to={`/info/${moment(item.Published.unix * 1000).format('YYYY')}/${item.Slug}`} className="c-info-list__anchor">{item.Title}<span className="c-info-list__arrow" style={{ backgroundImage:`url(${require('assets/img/rocket-on/bullet-footerNav.svg')})`}}></span></Link>
									</span>
								</li>
							)
						})
					}
				</ul>
			)
		}	
	}

	// const buildTopInfo = () => {

	// 	if (infoList === null) return false;
		
	// 	if (infoList.data.data.length === 0) {
	// 		return null
	// 	}
	// 	else {
	// 		return (
	// 			<div className="p-home-info">
	// 				<div className="c-block">
	// 					<div className="p-home-info__inner">
	// 						<h2 className="p-home-info__heading">{t('title_info')}</h2>
	// 						<div className="p-home-info__body">
	// 							<p className="p-home-info__text"><HashLink smooth to="#information" className="p-home-info__anchor js-smoothScroll">{infoList.data.data[0].Title}</HashLink></p>
	// 						</div>
	// 						<span className="p-home-info__arrow" onContextMenu={(e) => {
	// 							e.preventDefault(); 
	// 							e.stopPropagation(); 
	// 							return false
	// 						}} style={{
	// 							backgroundImage: `url(${require('assets/img/rocket-on/bullet-footerNav.svg')})`
	// 						}}></span>
	// 					</div>
	// 				</div>
	// 			</div>
	// 		)
	// 	}	
	// }

	// const handleModal = () => {
	// 	if (langModal === false) {
	// 		setScrollLock(true)
	// 		setLangModal(true)
	// 	}
	// 	else {
	// 		setScrollLock(false)
	// 		setLangModal(false)
	// 	}
	// }

	// const handleLock = () => {
	// 	if (toggleLock) {
	// 		return (
	// 			<ScrollLock isActive={scrollLock} />
	// 		)
	// 	} else {
	// 		return null;
	// 	}
	// }

	// useEffect( () => {
	// 	if (onlyWidth <= 767 || langModal === true) {
	// 		setToggleLock(true);
	// 	} else {
	// 		setToggleLock(false)
	// 	}
	// },[onlyWidth, langModal]);

	return (
		<>
			<div className="p-home-heading">
				<picture>
					<source srcSet={mvSp} media="(max-width: 767px)"></source>
					<source srcSet={mvPc} media="(min-width: 768px)"></source>
					<img onContextMenu={(e) => {e.preventDefault(); e.stopPropagation(); return false}} src={mvPc} alt=""/>
				</picture>
				<h1 className="p-home-heading__text">{t('common_ihic')} - Industrial Heritage Information Centre</h1>
			</div>

			{/* <Modal className="aniversary-modal" overlayClassName="aniversary-overlay" isOpen={langModal}>
				<iframe className="aniversary-modal-mv" width="560" height="315" src="https://www.youtube.com/embed/M0PvOUEz7Ac?si=NMsl3pPvsaNh-cBw" title="YouTube video player" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerpolicy="strict-origin-when-cross-origin" allowFullScreen></iframe>

				<button onClick={() => {handleModal()}} className="aniversary-modal__closeBtn js-modal--close"><img onContextMenu={(e) => {e.preventDefault(); e.stopPropagation(); return false}} src={require('assets/img/rocket-on/btn-langSelect-close.svg')} alt="閉じる"/></button>
			</Modal> */}

			<div className="p-home-anniversary">
				<div className="c-block p-home-anniversary-inner">
					<div className="p-home-anniversary__group">
						<p className="p-home-anniversary__text" dangerouslySetInnerHTML={{__html: t('home_anniversary_text')}}/>
						{/* <button className="p-home-anniversary__btn c-btn--regular" onClick={() => {handleModal()}}>{t("home_anniversary_btn")}</button> */}
					</div>
					<a href="https://10anniversary-jmir.com/" target="_blank" rel="noopener noreferrer" className="p-home-anniversary__img">
						<img src={require('assets/img/badge_10th.png')} alt={ t('home_anniversary_img') }/>
					</a>
				</div>
			</div>

			<div className="p-home-about">
				<div className="c-block">
					<div className={classNames("p-home-about__header", {
						"p-home-about__header--en-US": currentLng === "en-US"
					})}>
						<h2 className="p-home-about__title">{t('common_ihic')}</h2>
						<div className={classNames("p-home-about__price_wrapper", {
							"p-home-about__price_wrapper--en-US": currentLng === "en-US"
						})}>
							<p className={classNames("p-home-about__price", {
								"p-home-about__price--en-US": currentLng === "en-US"
							})}>{t('text_free_asmission')}</p>
							<p className={classNames("p-home-about__price", {
								"p-home-about__price--en-US": currentLng === "en-US"
							})}>{t('text_reservation_required')}</p>
						</div>
					</div>
					<div className="p-home-about__body">
						<div className="p-home-about__info">
							<p className="p-home-about__openHour" dangerouslySetInnerHTML={{__html:t('text_ihic_info')}} />
							<p className="p-home-about__address" dangerouslySetInnerHTML={{__html:t('text_ihic_address')}} />
						</div>
						<p className="p-home-about__btn"><Link to="/access" className="c-btn c-btn--regular">{t('title_access')}</Link></p>
					</div>
				</div>
			</div>

			<div id="information" className="p-home-news">
				<div className="c-block c-block--w720">
					<h2 className="p-home-news__heading">{t('title_info')}</h2>
					<div className="p-home-news__body">
						{buildArticle()}
						<p className="p-home-news__btns"><Link to="/info/" className="c-btn c-btn--regular">{t('text_all')}</Link></p>
					</div>
				</div>
			</div>

			<div className="p-home-covid19">
				<span className="p-home-covid19__bg--sp forSP" style={{
					backgroundImage: `url(${require('assets/img/rocket-on/bg-home-covid19--sp.png')}), url(${require('assets/img/rocket-on/bg-home-covid19--sp.png')})`
				}}></span>
				<span className="p-home-covid19__bg--pc forPC" style={{
					backgroundImage: `url(${require('assets/img/rocket-on/bg-home-covid19.png')})`
				}}></span>
				<div className="c-block c-block--w740">
					<h2 className="p-home-covid19__leadCopy">{t('subtitle_info_booking')}</h2>
					<p className="p-home-covid19__text">{t('text_info_booking')}</p>
					{currentLng === 'en-US' && <p className="p-home-covid19__text">{t('text_info_booking_2')}</p>}
					<p className="p-home-covid19__btns"><a href={`https://booking.ihic.jp/l/${currentLng}/`} target="_blank" rel="noopener noreferrer" className="c-btn c-btn--reserve">{t('btn_booking')}</a></p>
				</div>
			</div>

			<div className="p-home-overview">
				<div className="p-home-overview__bgRed">
					<div className="p-home-overview-about">
						<div className="p-home-overview-about__text">
							<h2 className="p-home-overview-about-heading">
								<span className="p-home-overview-about-heading__ja">{t('common_ihic')}</span>
								<span className="p-home-overview-about-heading__en">{t('text-en-ihic')}</span>
							</h2>
							<p className="p-home-overview-about__paragraph">{t('Text-top-ihic-desc_1')}</p>
							<p className="p-home-overview-about__paragraph">{t('Text-top-ihic-desc_2')}</p>
						</div>
						<p className="p-home-overview-about__img">
							<img onContextMenu={(e) => {e.preventDefault(); e.stopPropagation(); return false}} src={require('assets/img/rocket-on/img-home-overview-img.jpg')} alt="" className="p-home-overview-about__photo"/>
							<span className="p-home-overview-about__imgCaption" dangerouslySetInnerHTML={{__html:t('text-top-ihic-img-alt')}} />
						</p>
					</div>
					<p className="p-home-overview-about__btn"><Link to="/about-us" className="c-btn c-btn--regular">{t('btn_more')}</Link></p>
				</div>
				<div className="p-home-overview-link c-block">
					<div className="p-home-overview-link__item">
						<h3 className="p-home-overview-link-heading">
							<span className="p-home-overview-link-heading__en">{t('text-en-exhibits')}</span>
							<span className="p-home-overview-link-heading__ja">{t('top-text-exhibits')}</span>
						</h3>
						<div className="p-home-overview-link__img">
							<img onContextMenu={(e) => {e.preventDefault(); e.stopPropagation(); return false}} src={require('assets/img/rocket-on/img-home-overview-exhibits.jpg')} alt="" className=""/>
							<Link to="/exhibition" className="p-home-overview-link__btn">{t('btn_more')}</Link>
						</div>
					</div>
					<div className="p-home-overview-link__item">
						<h3 className="p-home-overview-link-heading">
							<span className="p-home-overview-link-heading__en">{t('text-en-usersguide')}</span>
							<span className="p-home-overview-link-heading__ja">{t('top-text-usersguide')}</span>
						</h3>
						<div className="p-home-overview-link__img">
							<img onContextMenu={(e) => {e.preventDefault(); e.stopPropagation(); return false}} src={require('assets/img/rocket-on/img-home-overview-userGuide.jpg')} alt="" className=""/>
							<Link to="/guide" className="p-home-overview-link__btn">{t('btn_more')}</Link>
						</div>
					</div>
				</div>
			</div>

			<div className={classNames("p-home-succession", {
				"p-home-succession--en-US": currentLng === "en-US"
			})}>
				<span className="p-home-succession__bg--sp forSP" style={{
					backgroundImage: `url(${require('assets/img/rocket-on/bg-home-succession--sp.jpg')})`
				}}></span>
				<span className="p-home-succession__bg--pc forPC" style={{
					backgroundImage: `url(${require('assets/img/rocket-on/bg-home-succession--pc.jpg')})`
				}}></span>
				<div className={classNames("p-home-succession_wrapper", {
					"p-home-succession_wrapper--en-US": currentLng === "en-US"
				})}>
					<h2 className="p-home-succession__heading"><img onContextMenu={(e) => {e.preventDefault(); e.stopPropagation(); return false}} src={require('assets/img/rocket-on/img-home-succession.svg')} alt={t('title_passing')} className=""/></h2>
					{currentLng === 'en-US' && <h2 className="p-home-succession__heading__text">{t('title_passing')}</h2>}
					<p className="p-home-succession__text" dangerouslySetInnerHTML={{__html:t('text_passing')}} />
				</div>
			</div>

			<div className="p-home-topics">
				<h2 className="p-home-topics-heading">
					<span className="p-home-topics-heading__ja" dangerouslySetInnerHTML={{__html: t('common_event_title')}}></span>
					<span className="p-home-topics-heading__en">{t('common_event_title_en')}</span>
				</h2>
				<div className="p-home-topics__body">
					{buildTopics()}
				</div>
				<p className="p-home-topics__btns"><Link to="/event/" className="c-btn c-btn--regular">{t('text_all')}</Link></p>
			</div>

			<div id="information" className="p-home-news">
				<div className="c-block c-block--w720">
					<h2 className="p-home-news__heading">{t('title_info')}</h2>
					<div className="p-home-news__body">
						{buildArticle()}
						<p className="p-home-news__btns"><Link to="/info/" className="c-btn c-btn--regular">{t('text_all')}</Link></p>
					</div>
				</div>
			</div>
			{/* {handleLock()} */}
		</>
	);
}
